import { getCities, getStates } from '@brazilian-utils/brazilian-utils'

const brazilianStates = getStates() as ReadonlyArray<
  ReturnType<typeof getStates>[number]
>
const brazilianStatesNames = brazilianStates.map(state => state.name)
export type BrazilianStatesNames = (typeof brazilianStatesNames)[number]

const brazilianStatesKeys = brazilianStates.map(state => state.code)
export type BrazilianStatesKeys = (typeof brazilianStatesKeys)[number]

const brazilianStateCodeToName = (code: BrazilianStatesKeys) =>
  brazilianStates.find(state => state.code === code)!.name

const brazilianStateNameToCode = (name: BrazilianStatesNames) =>
  brazilianStates.find(state => state.name === name)!.code

const brazilianCities = getCities()
type BrazilianStateCities = Record<BrazilianStatesKeys, string[]>
const brazilianStateCities = brazilianStates.reduce<BrazilianStateCities>(
  (acc, state) => {
    return { ...acc, [state.code]: getCities(state.code) }
  },
  {} as BrazilianStateCities,
)

const geo = {
  brazilianStates,
  brazilianCities,
  brazilianStateCities,
  brazilianStatesNames,
  brazilianStatesKeys,
  brazilianStateCodeToName,
  brazilianStateNameToCode,
}

export default geo
